import React, { Component } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

import Roles from './Roles';

class Account extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {
    //       accounts: accounts,
    //     };
    // }

    render() {
        const {account_name, account_id, roles} = this.props.account;        

        return (            
            <Accordion key={account_id}>
            <Card
                  bg='light'
                  text={'dark'}
                  style={{ width: '100%' }}
            >
                {/* <Card.Header className="custom-card-header"> */}
                {/* <Accordion.Toggle as={Card.Header} variant="link" eventKey="0" className="custom-accordion-toggle"> */}
                <Accordion.Toggle as={Card.Header} variant="link" eventKey="0">
                    {account_name} ({account_id})
                </Accordion.Toggle>
                {/* </Card.Header> */}
                <Accordion.Collapse eventKey="0">
                <Card.Body>
                    {roles.map((role) => <Roles role_name={role.role_name} key={role.role_name} account_id={account_id} /> )}
                </Card.Body>
                </Accordion.Collapse>
            </Card>
            </Accordion>
        )
    }
}

export default Account;