import { Auth } from 'aws-amplify';

// Amplify.configure({
const config = {
    Auth: {
        
        // REQUIRED - Amazon Cognito Region
        region: 'eu-central-1',
        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: 'eu-central-1_7OgTzx7bO',
        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: '7q59q92u4v0m0ln0rp6q4shpf1',
        oauth : {
          domain: 'cam.auth.eu-central-1.amazoncognito.com',
          scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
          redirectSignIn: 'https://tvm.tools.co.sws.siemens.com',
          redirectSignOut: 'https://tvm.tools.co.sws.siemens.com',
          // redirectSignIn: 'http://localhost:3000',
          // redirectSignOut: 'http://localhost:3000',
		  //cam.tools.co.sws.siemens.com and tvm.tools.co.sws.siemens.com 
          responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        },
    },
    API: {
        endpoints: [
            {
                name: "awstvm",
                endpoint: "https://vu5ugnxwz1.execute-api.eu-central-1.amazonaws.com", //DEV
                // endpoint: "https://g5hvp3tedd.execute-api.us-east-1.amazonaws.com", //QA
                custom_header: async () => {
                  return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
                }
            }
        ]
    }
  };

export default config
